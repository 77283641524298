/* Define the default margin for all elements with this class */
.bottom-margin-sm {
    margin-bottom: 0; /* Default to no margin */
}

/* Apply bottom margin for small screens */
@media (max-width: 768px) {
    .bottom-margin-sm {
        margin-bottom: 25px; /* Adjust this value as needed */
    }
}

.left-margin-sm {
    margin-left: 0; /* Default to no margin */
}

/* Apply left margin for small screens */
@media (max-width: 768px) {
    .left-margin-sm {
        margin-left: 25px; /* Adjust this value as needed */
    }
}

.right-margin-sm {
    margin-right: 0; /* Default to no margin */
}

/* Apply right margin for small screens */
@media (max-width: 768px) {
    .right-margin-sm {
        margin-right: 25px; /* Adjust this value as needed */
    }
}

.top-margin-sm {
    margin-top: 0; /* Default to no margin */
}

/* Apply top margin for small screens */
@media (max-width: 768px) {
    .top-margin-sm {
        margin-top: 25px; /* Adjust this value as needed */
    }
}