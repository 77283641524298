.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    z-index: 1000;
    width: auto;
    max-width: 300px;
    border-radius: 10px;
    margin: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Adjust for smaller screens */
  @media (max-width: 768px) {
    .cookie-banner {
      left: 0;
      right: 0;
      border-radius: 0;
      max-width: none;
    }
  }
  
  .cookie-banner p {
    margin: 0 0 10px 0;
    font-size: 14px;
  }
  
  .cookie-banner button {
    width: 100%;
    font-size: 14px;
  }
  