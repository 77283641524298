/* Content section styling */
.content-section {
    background-color: #f8f9fa;
}

.os-img {
    height: 150px;
    width: auto;
    padding: 10px;
}

.text-class {
    text-align: left;
}