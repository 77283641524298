.equipped-gear-container {
    background-color: #EC7801;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    border-radius: 10px;
    border: 1px #EC7801;
}

.gear-button {
    width: 60px;
    /* Adjust size as needed */
    height: 60px;
    background-size: cover;
    background-position: center;
    border: 2px solid #F9B266;
    background-color: #FF9E34;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px;
}

.equipped-gear-canvas {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}

.gear-button-icon {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.gear-button:focus {
    outline: none;
}

.text-white {
    color: white !important;
}