/* Define the keyframes for the border flash */
@keyframes slowFlash {
  0% {
    border-color: transparent;
  }

  50% {
    border-color: lightgreen;
  }

  100% {
    border-color: transparent;
  }
}

/* Class to apply the slow-flashing border */
.slow-flash-border {
  border: 1px solid transparent;
  animation: slowFlash 4s infinite;
}

.markdown-text {
  color: rgb(192, 192, 192);
}

.markdown-container {
  text-align: left;
  padding: 5px;
}

.guide-button-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 30%;
  font-size: 0.8em;
  overflow: hidden;
}

.guide-button-right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 30%;
  font-size: 0.8em;
  overflow: hidden;
}

.guide-button-middle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 40%;
  font-size: 0.8em;
  overflow: hidden;
}