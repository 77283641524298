.model-animation-viewer {
    width: 100vw;
    height: 100vh;
    position: relative;
}

/* Animation Panel */
.animation-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #222;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 10px;
    box-sizing: border-box;
    transform: translateY(0);
}

.animation-panel.closed {
    transform: translateY(100%);
}

/* Horizontal Scroll */
.animation-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
}

.animation-buttons::-webkit-scrollbar {
    height: 6px;
}

.animation-buttons::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 3px;
}

.animation-buttons::-webkit-scrollbar-track {
    background: #333;
}

/* Animation Button */
.animation-button {
    padding: 10px 20px;
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.animation-button:hover {
    background-color: #555;
}

.animation-button.active {
    background-color: #008cba;
}

/* Toggle Button */
.toggle-button {
    position: absolute;
    bottom: 110px;
    right: 20px;
    background-color: #444;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}

.toggle-button:hover {
    background-color: #555;
}
