/* Make it be on the right of the parent div */
#basic-navbar-nav {
    width: 100%;
}

#my-navbar {
    width: 100%;
    padding: 5px;
    border-bottom: 2px solid #333333;
}

#editor {
    height: 100%;
    width: 100%;
}


/* Scrollable textarea, fixed height */
#editor-container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.unity-container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.cm-line {
    text-align: left;
}

/* White font */
.white-header {
    color: rgb(192, 192, 192);
}

.white-header:hover {
    color: rgb(255, 255, 255);
}

.res-header {
    color: rgb(255, 255, 255);
}

.my-button {
    color: rgb(192, 192, 192);
    cursor: pointer;
    transition: background-color 1s ease;
}

.clicked {
    color: rgb(142, 255, 167);
}

/* Fade to green when clicked */
.clicked:active {
    color: rgb(142, 255, 167);
}

/* Fade to green when hovered */
.my-button:active {
    color: rgb(142, 255, 167);
    transition: background-color 1s ease;
}

.copied {
    position: absolute;
}

.navigation-item:disabled {
    color: #868686;
}

.white-text {
    color: rgb(255, 255, 255);
}

.black-text {
    color: rgb(0, 0, 0);
}

.red-text {
    color: rgb(255, 0, 0);
}

.yellow-text {
    color: rgb(255, 255, 0);
}

.green-text {
    color: rgb(0, 255, 0);
}

.explorer-entry {
    color: rgb(192, 192, 192);
    text-align: left;
    padding-top: 10px;
}

.explorer-card {
    padding: 0;
    margin: 0;
    background-color: #1E1E1E;
    height: 22.4px;
    font-family: monospace;
}

.mp-0 {
    margin: 0;
    padding: 0;
}

.explorer-card:hover {
    background-color: rgb(70, 70, 70);
}

#explorer-container {
    height: 100%;
    width: 100%;
    /*max-height: calc(95vh - 90px);*/
    background-color: #1E1E1E;
    padding-left: 10px;
    padding-right: 10px;
}

.wh-100 {
    height: 100%;
    width: 100%;
}

.h-10 {
    height: 10%;
}

.h-90 {
    height: 90%;
}

/* Scrollable */
.scrollable {
    max-height: 90%;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    background-color: #1E1E1E;
}

.dragable-size {
    max-height: calc(100% - 25px);
}

.scrollable-h-100 {
    max-height: calc(100% - 25px);
    overflow-y: auto;
    width: 100%;
    margin: 0;
    background-color: #1E1E1E;
}

.editor-header {
    min-height: 35px;
    width: 100%;
    height: 35px;
    background-color: #1E1E1E;
    text-align: left;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
    color: wheat;
    overflow-x: auto;
    white-space: nowrap;
}

.editor-main {
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.editor-main-top {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 30px);
    padding-left: 10px;
    padding-right: 10px;
}

.explorer-header {
    height: 30px;
    background-color: #1E1E1E;
    text-align: left;
    padding: 5px;
    color: wheat;
    margin-bottom: 4px;
}

#basic-nav-dropdown {
    color: rgb(192, 192, 192);
}

.wheat-text {
    color: wheat;
}

.hint-card {
    left: 200;
}

.danger {
    color: brown;
    cursor: pointer;
    font-family: monospace;
}

.primary {
    color: lightseagreen;
    cursor: pointer;
    font-family: monospace;
}

.secondary {
    color: rgb(192, 192, 192);
    cursor: pointer;
    font-family: monospace;
}

.logo-text {
    padding-left: 10px;
}

.suggestion {
    color: rgb(192, 192, 192);
    float: right;
}

.border-bottom {
    border-bottom: 2px solid rgb(192, 192, 192);
}

.border-bottom-gray {
    border-bottom: 2px solid #333333;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.left-text {
    text-align: left;
}

.locale-text {
    color: rgb(192, 192, 192);
}

.locale-input {
    color: rgb(192, 192, 192);
    border: 0;
    border-bottom: 1px solid #333333;
}

.border-right {
    border-right: 2px solid rgb(192, 192, 192);
}

.no-overflow {
    white-space: nowrap;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}

.bottom-pm {
    margin-bottom: 20px;
}

.title-text {
    color: rgb(82, 255, 77);
}

.success {
    color: rgb(82, 255, 77);
    font-family: monospace;
}

.no-border {
    border: 0;
}

.node-editor {
    width: 100%;
    height: 100%;
}

.react-flow__attribution {
    display: none;
}

.node-border {
    border: 2px solid rgb(192, 192, 192);
}

.node-prop {
    padding: 5px;
}

.generic-input {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid #333333;
    border-radius: 0px;
}

.generic-button {
    color: rgb(192, 192, 192);
    border: 1px solid #333333;
    text-align: left;
    padding: 2px;
    margin: 2px;
}

.text-align-left {
    text-align: left;
}

.generic-button:hover {
    color: rgb(255, 255, 255);
}

.monospace {
    font-family: monospace;
}

.resource-explorer-file {
    color: rgb(192, 192, 192);
    padding: 5px;
    margin: 5px;
    border: 1px solid #333333;
    width: 100%;
    height: 100%;
}

.filename-text {
    color: rgb(192, 192, 192);
    font-size: 11px;
    max-lines: 1;
    max-height: 55px;
}

.resource-explorer-container {
    padding: 5px;
    height: 100px;
}

.resource-explorer-file-preview {
    padding: 5px;
    border: 2px solid #333333;
    width: 100%;
    height: 100%;
}

.resource-explorer-directory {
    background-color: rgb(0, 75, 71);
}

div.dropdown-menu.show {
    background-color: #1E1E1E;
    border: 1px solid #333333;
}

.dropdown-item {
    color: rgb(192, 192, 192);
}

.panel-header {
    background-color:  #333333;
    padding: 1px;
    cursor: move; /* Shows the move cursor */
    height: 22px;
}

.panel-content {
    padding-bottom: 1px;
    height: calc(100% - 22px);
    overflow: visible;
}

.panel-header-title {
    color: wheat;
    font-size: 15px;
    float: right;
    padding-right: 10px;
    padding-bottom: 2px;
}

.panel-container {
    width: 100%;
    height: 100%;
    border: 2px solid #333333;
    background-color: #1E1E1E;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 15px solid #333333;
}

/*body {
    background-color: #1E1E1E;
}*/

.explorer-inputs {
    width: 100%;
    height: 110px;
}

.explorer-mods {
    margin-top: 5px;
    width: 100%;
    height: calc(100% - 120px);
    border-right: 1px solid #696969;
}

.file-selector-button {
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top: 0;
    width: fit-content;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 0;
    margin-right: 0px;
}

.editor-files {
    width: 100%;
    height: 100%;
    
    /* Scroll horizontally */
    overflow-x: auto;
}

.file-close-button {
    color: #868686;
    cursor: pointer;
    float: right;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    font-size: 15px;
    border: 0px;
}

.hotkey-hint {
    color: #868686;
    font-size: 15px;
    float: right;
}

.navigation-item {
    width: 250px;
}

.log-line {
    width: 100%;
    height: 22px;
    padding: 5px;
}

.log-info {
    color: #dfdfdf;
}

.log-warning {
    color: #FFD700;
}

.log-error {
    color: #FF0000;
}

.log-debug {
    color: #56ff56;
}

.logger {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.log-container {
    width: 100%;
    height: fit-content;
    padding: 5px;
}

.log-time {
    color: #868686;
    float: right;
}

.log-msg {
    float: left;
}

.scroll-message {
    width: 100%;
    text-align: center;
    padding: 10px;
    color: #FFD700;
    position: relative;
    bottom: 30px;
}

.user-nav-dropdown {
    float: right;
}

.white-text a {
    color: rgb(207, 207, 207);
}

.white-text a:visited {
    color: rgb(207, 207, 207);
}

.white-text a:hover {
    color: rgb(163, 163, 163);
}

.white-text a:active {
    color: rgb(163, 163, 163);
}

.white-text a:disabled {
    color: #868686;
}

.navigation-item:disabled {
    color: #868686;
}

.nav-link.show {
    color: rgb(192, 192, 192) !important;
}

.dropdown-item.disabled {
    color: #868686 !important;
}

.array-element-border {
    padding: 10px;
    border: 1px solid #333333;
    margin-bottom: 5px;
}

.array-container {
}

.input-container {
    padding: 5px;
    border-right: 1px solid #696969;
    border-bottom: 1px solid #696969;
    margin-left: 2px;
}

.danger-button {
    background-color: #8B0000;
    color: white;
}

.primary-button {
    background-color: #20B2AA;
    color: white;
}

.secondary-button {
    background-color: #696969;
    color: white;
}

.error-screen-close {
    float: right;
    color: #696969;
    cursor: pointer;
}