.inventory-view {
    background-color: #040406;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #040406;
}

.equipped-view {
    padding: 10px;
}

.inventory-item {
    background-color: #1E1E1E;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    border: 1px solid #1E1E1E;
    height: 100px;
    width: 100px;
}

.inventory-icon {
    height: 100%;
    width: auto;
}

.inventory-item-view {
    background-color: #121212;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}

.inventory-item-name {
    background-color: #353938;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 5px;
}

.inventory-coin-span {
    text-align: start;
    margin-left: 5px;
}

/*
    A input box without any qualties, only text that can be edited, no + or - buttons
*/
.inventory-text-input {
    background-color: #12121200;
    color: #FFFFFF;
    border: 1px solid #12121200;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    appearance: textfield;
    -moz-appearance: textfield;
}

.inventory-text-input::-webkit-inner-spin-button,
.inventory-text-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rarity-row {
    display: flex;
    align-items: flex-end;
}

.diagonal-images {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.rarity-image {
    display: inline-block;
    position: relative;
}

.inventory-rarity-span {
    text-align: center;
    margin-left: 5px;
    width: 100%;
    height: 30px;
}

.inventory-rarity-img-1 {
    width: 20px;
    height: 16px;
    margin-top: 6px;
}

.inventory-rarity-img-2 {
    width: 24px;
    height: 20px;
    margin-top: 4px;
}

.inventory-rarity-img-3 {
    width: 28px;
    height: 24px;
    margin-top: 2px;
}

.inventory-rarity-img-4 {
    width: 32px;
    height: 28px;
}

.inventory-rarity-container {
    display: inline-block;
    position: relative;
}

.inventory-rarity-justify {
    margin-left: calc(50% - 64px);
}

/*
    Horizontal divider, brigher in the middle, fades out to the sides
*/
.inventory-item-view-divider {
    background: linear-gradient(to right,  #0C0C0C 0%,  #1C1C1C 25%,  #2C2C2C 50%,  #1C1C1C 75%,  #0C0C0C 100%);
    height: 1px;
    margin: 10px 0;
}