.anchor {
    width: 20px;
}

.header-font-size {
    font-size: 15px;
    height: 30px;
    border-top: 0;
}

.output-selector {
    background: transparent;
    border: 0;
    font-size: 12px;
}

.output-selector-option {
    font-size: 12px !important;
}

.right-text {
    text-align: right;
}

.left-text {
    text-align: left;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.b-0 {
    border: 0;
}

.content-right {
    padding-right: 20px;
}

.no-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.top-text {
    top: -20px;
    position: absolute;
}

.top-text-size {
    font-size: 15px;
}
