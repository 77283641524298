.resources-panel {
    height: 100%; /* Or a specific height */
    width: 100%; /* Or a specific width */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

/* Ensure content columns are scrollable independently */
.content-column {
    overflow-y: auto;
    height: 100%;
}