.database-panel {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;
}

.panel-section {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sql-panel-header {
    display: flex;
    align-items: start;
    padding: 0.5rem;
    border-bottom: 1px solid #696969;
    height: 50px; /* Fixed height for the header */
    flex-shrink: 0; /* Prevents the header from shrinking */
}

.sql-panel-header-divider {
    margin-right: 10px;
    border-left: 1px solid #696969;
    height: 100%;
    width: 5px;
    margin-left: 10px;
}

.panel-body {
    display: flex;
    flex: 1; /* Takes up all remaining space */
    height: 100%;
    overflow: hidden;
    padding-bottom: 20px;
}

.sql-editor,
.sql-results {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sql-editor {
    border-right: 1px solid #696969;
    overflow-y: auto;
}

.sql-results {
    overflow: auto;
}

.sql-results-table {
    width: 100%;
    border-collapse: collapse;
}

.sql-results-header {
    border-bottom: 1px solid #696969;
}

.sql-results-row {
    border: 1px solid rgb(51, 51, 51);
    background-color: #313131;
}

/* On hover */
.sql-results-row:hover {
    background-color: #3d3d3d;
}

.sql-results-row-odd {
    border: 1px solid rgb(51, 51, 51);
    background-color: #474747;
}

/* On hover */
.sql-results-row-odd:hover {
    background-color: #525252;
}

.sql-results-th {
    padding: 0.5rem;
    text-align: left;
    border-right: 1px solid #696969;
}

.sql-results-td {
    padding: 0.5rem;
    border-right: 1px solid #696969;
}

.sort-button {
    margin-left: 5px;
    cursor: pointer;
}