.dropdown-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.option-image {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between image and text */
}

.custom-dropdown .custom-dropdown-toggle::after {
    display: none;
}

.custom-dropdown .custom-dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
}