.error-screen-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;

    background-color: rgba(255, 0, 0, 0.1);
    max-height: calc(max(500px, 50vh));
    max-width: calc(max(500px, 50vw));
    width: fit-content;
    margin: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.error-screen-title-container {
    display: flex;
    text-align: left;
}

.error-screen-title {
    font-size: 1em;
    margin: 0.5em;
    color: #ff0000;
    font-family: monospace;
    padding-left: 2px;
}

.error-screen-message-container {
    display: flex;
    text-align: left;
    overflow: scroll;
    background-color: #1E1E1E;
    margin: 5px;
    opacity: 0.9;
    max-width: calc(max(500px, 50vw)-20px);
}

.error-screen-message {
    font-size: 0.8em;
    margin: 0.5em;
    font-family: monospace;
    color: wheat;
    padding-left: 3px;
}

.error-screen-paragraph {
    margin: 0px;
}