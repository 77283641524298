/* Background image fade-in */
.hero-section {
    height: 100vh;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.hero-section.fade-in {
    opacity: 1 !important;
}

.hero-section.fade-out {
    opacity: 0 !important;
}

.hero-background {
    height: 100%;
    width: 100%;
}

/* Make the h1 fixed */
.fixed-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    padding: 10px 20px;
    border-radius: 10px;
    text-align: center;
    white-space: nowrap; /* Prevents text wrapping */
}

.hero-background.image1 {
    background-image: url('/public/images/1.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-background.image2 {
    background-image: url('/public/images/2.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-background.image3 {
    background-image: url('/public/images/3.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-background.image4 {
    background-image: url('/public/images/4.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-background.image5 {
    background-image: url('/public/images/5.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.second-hero-section {
    height: 100vh;
    background: url('https://via.placeholder.com/1920x1080') center center/cover no-repeat;
}

/* Arrow for scrolling */
.arrow {
    font-size: 3rem;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    animation: bounce 1.5s infinite;
}

/* Position the arrow at the bottom */
.scroll-arrow {
    position: absolute;
    bottom: 30px; /* Adjust based on how far from the bottom you want */
    left: 50%;
    transform: translateX(-50%);
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.my-img {
    height: 150px;
    width: auto;
    padding: 10px;
}

.cirular-img {
    border-radius: 50%;
}

/* Content section styling */
.content-section {
    background-color: #f8f9fa;
}